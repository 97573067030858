import styled from 'styled-components'

export const Box = styled.div`
  position: relative;
  z-index: 10;
  padding: max(5vh, 25px) ${({ theme }: any) => theme.padding};
  padding-left: 0;
  display: flex;
  align-items: center;
  color: #fff;
`

export const Menu = styled.svg`
  color: #fff;
  cursor: pointer;
  transition: 0.2s;
  &:hover {
    color: ${({ theme }: any) => theme.colors.red};
  }
`

export const Left = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  gap: ${({ theme }: any) => theme.padding};
`

export const LogoBox = styled.div`
  position: relative;
  height: 100%;
  background-color: red;
  flex: 1;
  display: flex;
  height: 100%;
`

export const Logo = styled.svg`
  cursor: pointer;
  position: absolute;
  transform: translateY(-27%);
  @media screen and (max-width: 900px) {
    left: 50%;
    transform: translate(-65%, -27%);
  }
`

export const NestedList = styled.div`
  position: absolute;
  left: 0;
  top: 100%;
`

export const Inner = styled.div`
  margin-top: 1.5em;
  border-radius: 4px;
  padding: 1.5em;
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(5px);

  & > * {
    display: block;
    white-space: nowrap;
    color: ${({ theme }: any) => theme.colors.black};
    text-decoration: none;

    &:not(:last-child) {
      margin-bottom: 1em;
    }
  }
`

export const Right = styled.div`
  position: relative;
  font-size: 14px;
  width: ${({ theme }: any) => theme.rightSliderWidth};
  display: flex;
  gap: ${({ theme }: any) => theme.padding};
  padding-left: ${({ theme }: any) => parseInt(theme.padding) * 2 + 'vw'};
  font-weight: bold;
  @media screen and (max-width: 900px) {
    display: none;
  }

  & > * {
    position: relative;
    color: #fff;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 0.3em;
    cursor: pointer;

    svg {
      width: auto;
      height: 0.5em;
    }

    &:last-child {
      flex: 1;
      justify-content: flex-end;
    }

    ${NestedList} {
      opacity: 0;
      visibility: hidden;
      transition: 0.5s;
      transform: translateY(-50%);
    }

    &:hover {
      ${NestedList} {
        visibility: visible;
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
`
