import styled, { css } from 'styled-components'

export const Countdown = styled.div`
  position: relative;
  display: block;
  margin-bottom: 10vh;
  display: flex;
  gap: 0.2em;
  color: #fff;
  font-size: 4vw;

  & > div {
    display: flex;
    flex-flow: column;

    small {
      text-transform: uppercase;
      font-size: 14px;
      margin-left: 0.1em;
      font-weight: 450;
      color: ${({ theme }: any) => theme.colors.red};
    }
  }
`
