import { createGlobalStyle } from 'styled-components'
import reset from 'styled-reset'
import medium from '../fonts/medium.ttf'
import regular from '../fonts/regular.ttf'
import light from '../fonts/light.ttf'

export const GlobalStyle = createGlobalStyle`
${reset}

$ig-small-screen: 768px !default;
$ig-xsmall-screen: 480px !default;
$ig-white: #fff !default;
$ig-black: #000 !default;
$ig-blue: #337ab7 !default;
$ig-background-black: rgba(0, 0, 0, .4) !default;
$ig-transparent: rgba(0, 0, 0, 0) !default;
$ig-shadow: 0 2px 2px lighten($ig-black, 10%);

@mixin vendor-prefix($name, $value) {
  @each $vendor in ('-webkit-', '-moz-', '-ms-', '-o-', '') {
    #{$vendor}#{$name}: #{$value};
  }
}

// SVG ICON STYLES
.image-gallery-icon {
  /* color: $ig-white; */
  color: ${({ theme }: any) => theme.colors.red};
  
  transition: all .2s ease-out;
  appearance: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  outline: none;
  position: absolute;
  z-index: 4;
  filter: drop-shadow($ig-shadow);
  
  .image-gallery-svg  {
    transition: 0.2s;
    transform-origin: 50% 50%;
  }
  
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      .image-gallery-svg {
        transform: scale(1.1);
      }
    }
  }
  
  &:focus {
    // a11y support
    outline: 2px solid $ig-blue;
  }
}

.image-gallery-using-mouse {
  .image-gallery-icon {
    &:focus {
      outline: none;
    }
  }
}

.image-gallery-fullscreen-button,
.image-gallery-play-button {
  bottom: 0;
  padding: 20px;
  
  .image-gallery-svg {
    color: black;
    color: ${({ theme }: any) => theme.colors.red};
    height: 28px;
    width: 28px;
  }
  
  @media (max-width: $ig-small-screen) {
    padding: 15px;
    
    .image-gallery-svg {
      height: 24px;
      width: 24px;
    }
  }
  
  @media (max-width: $ig-xsmall-screen) {
    padding: 10px;
    
    .image-gallery-svg {
      height: 16px;
      width: 16px;
    }
  }
}

.image-gallery-fullscreen-button {
  right: 0;
}

.image-gallery-play-button {
  left: 0;
}

.image-gallery-left-nav,
.image-gallery-right-nav {
  padding: 50px 10px;
  top: 50%;
  transform: translateY(-50%);
  
  .image-gallery-svg {
    height: 120px;
    width: 60px;
  }
  
  @media (max-width: $ig-small-screen) {
    .image-gallery-svg {
      height: 72px;
      width: 36px;
    }
  }
  
  @media (max-width: $ig-xsmall-screen) {
    .image-gallery-svg {
      height: 48px;
      width: 24px;
    }
  }
  
  &[disabled] {
    cursor: disabled;
    opacity: .6;
    pointer-events: none;
  }
}

.image-gallery-left-nav {
  left: 0;
}

.image-gallery-right-nav {
  right: 0;
}
// End of Icon styles

.image-gallery {
  @include vendor-prefix('user-select', none);
  -webkit-tap-highlight-color: $ig-transparent;
  position: relative;
  
  &.fullscreen-modal {
    background: black;
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 5;
    
    .image-gallery-content {
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.image-gallery-content {
  position: relative;
  line-height: 0;
  top: 0;
  
  &.fullscreen {
    background: black;
  }
  
  /* .image-gallery-slide .image-gallery-image {
    max-height: calc(100vh - 80px); // 80 px for the thumbnail space
  } */
  
  &.left,
  &.right {
    .image-gallery-slide .image-gallery-image {
      max-height: 100vh;
    }
  }
}

.image-gallery-slide-wrapper {
  position: relative;
  
  &.left,
  &.right {
    display: inline-block;
    width: calc(100% - 110px); // 100px + 10px for margin
    
    @media (max-width: $ig-small-screen) {
      width: calc(100% - 87px); // 81px + 6px for margin
    }
  }
  &.image-gallery-rtl {
    direction: rtl;
  }
}

.image-gallery-slides {
  line-height: 0;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  text-align: center;
}

.image-gallery-slide {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  
  &.center {
    position: relative;
  }
  
  .image-gallery-image {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
  
  .image-gallery-description {
    background: $ig-background-black;
    bottom: 70px;
    color: $ig-white;
    left: 0;
    line-height: 1;
    padding: 10px 20px;
    position: absolute;
    white-space: normal;
    
    @media (max-width: $ig-small-screen) {
      bottom: 45px;
      font-size: .8em;
      padding: 8px 15px;
    }
    
  }
}

.image-gallery-bullets {
  bottom: 20px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  width: 80%;
  z-index: 4;
  
  .image-gallery-bullets-container {
    margin: 0;
    padding: 0;
    text-align: center;
  }
  
  .image-gallery-bullet {
    appearance: none;
    background-color: transparent;
    border: 1px solid $ig-white;
    border-radius: 50%;
    box-shadow: $ig-shadow;
    cursor: pointer;
    display: inline-block;
    margin: 0 5px;
    outline: none;
    padding: 5px;
    transition: all .2s ease-out;
    
    @media (max-width: $ig-small-screen) {
      margin: 0 3px;
      padding: 3px;
    }
    
    @media (max-width: $ig-xsmall-screen) {
      padding: 2.7px;
    }
    
    &:focus {
      transform: scale(1.2);
      background: $ig-blue;
      border: 1px solid $ig-blue;
    }
    
    &.active {
      transform: scale(1.2);
      border: 1px solid $ig-white;
      background: $ig-white;
    }
    
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background: $ig-blue;
        border: 1px solid $ig-blue;
      }
      
      &.active:hover {
        background: $ig-blue;
      }
    }
  }
}

.image-gallery-thumbnails-wrapper {
  position: relative;
  
  &.thumbnails-swipe-horizontal {
    touch-action: pan-y;
  }
  
  &.thumbnails-swipe-vertical {
    touch-action: pan-x;
  }
  
  &.thumbnails-wrapper-rtl {
    direction: rtl;
  }
  &.left,
  &.right {
    display: inline-block;
    vertical-align: top;
    width: 100px;
    
    @media (max-width: $ig-small-screen) {
      width: 81px; // 75px + 6px for border
    }
    
    .image-gallery-thumbnails {
      height: 100%;
      width: 100%;
      left: 0;
      padding: 0;
      position: absolute;
      top: 0;
      
      .image-gallery-thumbnail {
        display: block;
        margin-right: 0;
        padding: 0;
        
        + .image-gallery-thumbnail {
          margin-left: 0;
          margin-top: 2px;
        }
        
      }
      
    }
  }
  
  &.left,
  &.right {
    margin: 0 5px;
    
    @media (max-width: $ig-small-screen) {
      margin: 0 3px;
    }
  }
}

.image-gallery-thumbnails {
  overflow: hidden;
  padding: 5px 0;
  
  @media (max-width: $ig-small-screen) {
    padding: 3px 0;
  }
  
  .image-gallery-thumbnails-container {
    cursor: pointer;
    text-align: center;
    white-space: nowrap;
  }
  
}

.image-gallery-thumbnail {
  display: inline-block;
  border: 4px solid transparent;
  transition: border .3s ease-out;
  width: 100px;
  background: transparent;
  padding: 0;
  
  @media (max-width: $ig-small-screen) {
    border: 3px solid transparent;
    width: 81px;
  }
  
  + .image-gallery-thumbnail {
    margin-left: 2px;
  }
  
  .image-gallery-thumbnail-inner {
    display: block;
    position: relative;
  }
  
  .image-gallery-thumbnail-image {
    vertical-align: middle;
    width: 100%;
    line-height: 0;
    
  }
  
  opacity: 0.5;
  transition: 0.2s;
  
  &:hover {
    opacity: 1;
  }
  
  &.active,
  &:focus {
    outline: none;
    border: 4px solid ${({ theme: any }) => theme.colors.red};
    opacity: 1;
    
    @media (max-width: $ig-small-screen) {
      border: 3px solid ${({ theme: any }) => theme.colors.red};
    }
  }
  
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      outline: none;
      border: 4px solid $ig-blue;
      
      @media (max-width: $ig-small-screen) {
        border: 3px solid $ig-blue;
      }
    }
  }
  
}

.image-gallery-thumbnail-label {
  box-sizing: border-box;
  color: white;
  font-size: 1em;
  left: 0;
  line-height: 1em;
  padding: 5%;
  position: absolute;
  top: 50%;
  text-shadow: $ig-shadow;
  transform: translateY(-50%);
  white-space: normal;
  width: 100%;
  
  @media(max-width: $ig-small-screen) {
    font-size: .8em;
    line-height: .8em;
  }
}

.image-gallery-index {
  background: $ig-background-black;
  color: $ig-white;
  line-height: 1;
  padding: 10px 20px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 4;
  
  @media(max-width: $ig-small-screen) {
    font-size: .8em;
    padding: 5px 10px;
  }
}

@font-face {
  font-family: 'custom';
  src: url(${regular}) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'custom';
  src: url(${medium}) format('truetype');
  font-display: swap;
  font-weight: bold;
}

@font-face {
  font-family: 'custom';
  src: url(${light}) format('truetype');
  font-display: swap;
  font-weight: 100 ;
}

* {
  box-sizing: border-box;
}

input { 
  
  &[type='email'],  &[type='text'],  &[type='password'] {
    padding: 1em .8em;
    border-radius: 5px;
    border: 0;
    display: block;
    margin: 0;
    font-size: 14px;
    margin-bottom: 5px;
  }
  
}



html, body {
  position: relative;
  scroll-behavior: smooth;
}

body {
  font-family: ${({ theme }: any) => theme.fonts.custom};
  background-color: ${({ theme }: any) => theme.colors.black};
  color:  ${({ theme }: any) => theme.colors.gray};
  font-size: 18px;
  background-position: 50% 50%;
  background-size: cover;
  
}

html, body, #root {
  position: relative;
  width: 100%;
  height:100%;
  -webkit-text-size-adjust: 100%;
}

.wrap {
  position: relative;
  margin-left: auto;
  width: ${({ theme }: any) => `calc(100% - ${theme.padding})`};
  max-width: 1500px;
  overflow-x: hidden;
  
  &.fullheight {
    min-height: 100%;
  }
  
  &.textWrap {
    padding-right: ${({ theme }: any) => theme.padding};
    overflow-x: visible;
    p {
      max-width: 1200px;
    }
  }
}

p {
  line-height: 150%;
  font-family:  ${({ theme }: any) => theme.fonts.default};
  font-weight: 250;
  margin-bottom: 1.2em;
  font-size: 16px;
  
  &::selection {
    background: #fff;
    color: ${({ theme }: any) => theme.colors.black};
  }
}

hr {
  
}

h2 {
  color: #fff;
  font-weight: bold;
  font-size: max(4vw, 35px);
  margin-bottom: .7em;
}

h3 {
  color: #fff;
  font-weight: bold;
  font-size: max(1.5vw, 22px);
  margin-bottom: .7em;
  line-height: 150%;
}
h4 {
  color: #fff;
  font-weight: bold;
  margin-bottom: 1em;
}

input[type='checkbox'] {
  margin-right: .5em;
  display: inline-block;
  vertical-align: middle;
}

table {
  position: relative;
  width: 100%;
  margin-bottom: 3em;
  td {
    text-align: center;
    padding: .5em 0.2em;
    vertical-align: middle;
    transition: 0.2s;
  }
  th {
    white-space: nowrap;
    color: #fff;
    vertical-align: bottom;
    padding: 1em;
    text-transform: uppercase;
    border-bottom: 1px solid ${({ theme }: any) => theme.colors.red};
    font-size: 0.9em;
  }
  
  th, td {
    &:first-child {
      padding-left: 0 !important;
    }
    &:last-child {
      padding-right: 0;
    }
  }
  
  tr:hover{
    td {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
  
}

ul {
  &.contentList {
    margin-bottom: 2em;
    list-style-type: circle;
    list-style-position: inside;
    font-family: ${({ theme }: any) => theme.fonts.default};
    font-size: 1em;
    
    
    li  {
      margin-bottom: .7em;
    }
  }
}


`

export const theme = {
  colors: {
    black: '#131A25',
    red: '#BB163F',
    gray: '#B9B9B9',
    green: '#90EB9E',
    blue: '#508CAE',
    yellow: '#DAA829',
  },
  fonts: {
    default: `-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif`,
    custom: 'custom',
  },
  padding: '3.5vw',
  rightSliderWidth: '70%',
}
