const Logo = ({ dark, ...restProps }: { dark?: boolean; restProps?: any }) => {
  let color = '#fff'

  if (dark) {
    color = '#131A25'
  }

  return (
    <svg
      {...restProps}
      width='106'
      height='50'
      viewBox='0 0 106 50'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.354 30.2539H5.88856L3.49084 36.6361L0 32.4753L6.27646 16.9253H11.0367L17.3131 33.0748H12.3061L11.354 30.2539ZM10.0141 26.3399L8.63893 22.2849L7.22849 26.3399H10.0141ZM30.6418 16.9253L24.5769 33.0395H19.8166L13.7165 16.9253H18.9351L22.3554 26.3399L25.8815 16.9253H30.6418ZM40.1975 24.5416H44.4288V31.3822C42.6657 32.7222 40.268 33.4274 38.1523 33.4274C33.5331 33.4274 30.1481 29.9365 30.1481 25C30.1481 20.0282 33.5331 16.5374 38.2581 16.5374C41.079 16.5374 43.3709 17.6657 44.8166 19.6756L41.7137 22.7433C40.8322 21.5444 39.7743 20.945 38.5049 20.945C36.3893 20.945 35.0141 22.4965 35.0141 25C35.0141 27.3978 36.3893 29.0198 38.4344 29.0198C39.0339 29.0198 39.598 28.914 40.1975 28.6319V24.5416ZM56.5938 30.2186H51.1284L50.1763 33.0395H45.2398L51.5515 16.9253H56.3117L62.5882 33.0395H57.5811L56.5938 30.2186ZM55.2539 26.3047L53.8434 22.2497L52.4683 26.3047H55.2539ZM64.7743 21.1213H60.543V16.89H73.8012V21.1213H69.6051V33.0395H64.7743V21.1213ZM74.5064 24.9647C74.5064 20.0635 77.9267 16.5374 82.6164 16.5374C87.2708 16.5374 90.6911 20.0635 90.6911 24.9647C90.6911 29.8308 87.2708 33.3921 82.6164 33.3921C77.9267 33.3921 74.5064 29.8308 74.5064 24.9647ZM85.8251 24.9647C85.8251 22.4612 84.3794 20.9097 82.6164 20.9097C80.8181 20.9097 79.3724 22.4612 79.3724 24.9647C79.3724 27.4683 80.8181 28.9845 82.6164 28.9845C84.3794 28.9845 85.8251 27.4683 85.8251 24.9647ZM98.3075 28.9845H97.0028V33.0395H92.1368V16.89H99.0832C103.068 16.89 105.606 19.2525 105.606 22.9901C105.606 25.3174 104.619 27.0451 102.891 28.0325L105.924 33.0395L102.891 36.6009L98.3075 28.9845ZM98.7659 21.1213H97.0028V24.8942H98.7659C100.106 24.8942 100.74 24.189 100.74 22.9901C100.74 21.8265 100.106 21.1213 98.7659 21.1213Z'
        fill={color}
      />
      <path
        d='M67.2779 13.3286L58.0043 2.29196L60.7546 0L67.2779 7.82792L73.8364 0L76.5515 2.29196L67.2779 13.3286ZM53.8434 0.0352631L63.1171 11.0719L60.402 13.3639L53.8434 5.57122L47.2849 13.3639L44.5698 11.0719L53.8434 0.0352631ZM31.1354 2.29196L33.8505 0L40.409 7.82792L46.9323 0L49.6827 2.29196L40.409 13.3286L31.1354 2.29196ZM76.5515 47.708L73.8364 50L67.2779 42.2073L60.7546 50L58.0043 47.708L67.2779 36.6714L76.5515 47.708ZM44.5698 38.8928L47.2849 36.6008L53.8434 44.4288L60.402 36.6008L63.1171 38.8928L53.8434 49.9647L44.5698 38.8928ZM40.409 36.6361L49.6474 47.6728L46.9323 49.9647L40.409 42.1721L33.8505 49.9647L31.1354 47.6728L40.409 36.6361Z'
        fill='#BB163F'
      />
    </svg>
  )
}

export default Logo
