import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import * as s from './Styles'
import * as React from 'react'
const PrintMail = React.lazy(() => import('components/printMail/PrintMail'))

export const ShowEmail = () => {
  let location = useLocation()

  const [roboCheckState, setRobotCheckState] = useState(false)

  const [showMail, setShowMail] = useState(false)

  function checkSetter() {
    setRobotCheckState(true)
    setTimeout(() => setShowMail(true), 800)
  }

  useEffect(() => {
    setRobotCheckState(false)
    setShowMail(false)
  }, [location])

  return (
    <>
      <h3>Send Us Email</h3>
      <s.NotRobotBox
        onClick={() => checkSetter()}
        roboCheckState={roboCheckState}>
        {!showMail ? (
          <>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              stroke='currentColor'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
              className='feather feather-check-square'>
              <g className='checkMark'>
                <polyline points='9 11 12 14 22 4'></polyline>
              </g>
              <g>
                <path d='M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11'></path>
              </g>
            </svg>
            <span>I’m not a robot</span>
          </>
        ) : (
          <React.Suspense fallback={<div>loading...</div>}>
            <PrintMail />
          </React.Suspense>
        )}
      </s.NotRobotBox>
    </>
  )
}

export default ShowEmail
