import * as s from './Styles'
import { ReactComponent as Menu } from 'assets/img/menu.svg'
import Logo from 'components/logo/Logo'
import { ReactComponent as ArrowBottom } from 'assets/img/chevron-down.svg'
import { Link } from 'react-router-dom'
import { useContext } from 'react'
import { AppCtx } from 'App'

const Headerline = () => {
  const appContext = useContext(AppCtx)

  function menuHandler() {
    if (appContext) {
      const { isMenuOpen, setIsMenuOpen } = appContext
      setIsMenuOpen(!isMenuOpen)
    }
  }

  function partnerHandler() {
    if (appContext) {
      const { isPartnerLoginOpen, setIsPartnerLoginOpen } = appContext
      setIsPartnerLoginOpen(!isPartnerLoginOpen)
    }
  }

  function contactsHandler() {
    if (appContext) {
      const { isContactLayerOpen, setIsContactLayerOpen } = appContext
      setIsContactLayerOpen(!isContactLayerOpen)
    }
  }

  return (
    <s.Box>
      <s.Left>
        <s.Menu
          as={Menu}
          onClick={() => menuHandler()}
        />
        <s.LogoBox>
          <Link to='/'>
            <s.Logo as={Logo} />
          </Link>
        </s.LogoBox>
      </s.Left>
      <s.Right>
        <Link to='/'>Home</Link>
        <div>
          Products <ArrowBottom />
          <s.NestedList>
            <s.Inner>
              <Link to='/products-aim'>Infrastructure Manager</Link>
              <Link to='/products-avstudio'>AVstudio</Link>
              <Link to='/products-gui'>Crestron HTML5 GUI</Link>
            </s.Inner>
          </s.NestedList>
        </div>
        <Link to='/services'>Services</Link>
        <div onClick={() => (window.location.href = 'https://support.avgator.com/')}>Support</div>
        <div onClick={() => contactsHandler()}>Contact us</div>
        <div onClick={() => partnerHandler()}>Partner login</div>
      </s.Right>
    </s.Box>
  )
}

export default Headerline
