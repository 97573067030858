import styled from 'styled-components'


export const Box = styled.div`
  position: relative;
  font-size: 1rem;

  svg {
    margin-right: .35em;
  }
`
