import styled, { css } from 'styled-components'

export const InfoSection = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3vmax;
  padding-bottom: 3vw;

  @media screen and (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`

export const InfoBox = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  min-height: 60vh;
  gap: 3vh;
  text-align: center;
  padding: 3vw;
  background: linear-gradient(
    139.62deg,
    #3c3c3c -2.28%,
    rgba(46, 64, 81, 0.32) 37.1%
  );

  p {
    font-size: 16px;
    font-family: ${({ theme }: any) => theme.fonts.default};
    line-height: 150%;
  }
`

export const CornerIcon = styled.svg`
  position: absolute;
  z-index: 3;
  right: 8px;
  top: 8px;
  width: 20px;
  height: auto;
`
export const Link = styled.a`
  position: absolute;
  z-index: 3;
  right: 0;
  bottom: 0;
  width: 170px;
  background-color: ${({ theme }: any) => theme.colors.red};
  color: #fff;
  padding: 1.3em 0;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  border-radius: 5px 0 0 0;
`
