import * as s from './Styles'
import { ReactComponent as Chevronicon } from 'assets/img/chevron-down.svg'
import { ReactComponent as ArrowLeft } from 'assets/img/arrow-left-circle.svg'
import { Link, useLocation } from 'react-router-dom'
import { useContext } from 'react'
import { AppCtx } from 'App'
import { useState, useEffect, useCallback } from 'react'
import smoothscroll from 'smoothscroll-polyfill'

smoothscroll.polyfill()

const Menu = () => {
  const appContext = useContext(AppCtx)

  let location = useLocation()

  useEffect(() => {
    if (!location.hash) {
      window.scrollTo(0, 0)
    } else {
      let element = document.getElementById(location.hash.substring(1))
      // console.log(element)
      // console.log(location.hash)
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
        })
      }
    }
  }, [location])

  const [activeNestedMenu, setActiveNestedMenu] = useState<string | null>(null)

  function handleActiveNested(name: string) {
    if (activeNestedMenu === name) {
      setActiveNestedMenu(null)
    } else {
      setActiveNestedMenu(name)
    }
  }

  function checkNestedActive(name: string) {
    if (name === activeNestedMenu) {
      return true
    } else {
      return false
    }
  }

  const escFunction = useCallback(
    (event) => {
      if (event.key === 'Escape') {
        if (appContext) {
          const { setIsMenuOpen } = appContext

          setIsMenuOpen(false)
        }
      }
    },
    [appContext]
  )

  function closeMenu() {
    if (appContext) {
      const { setIsMenuOpen } = appContext
      setIsMenuOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false)

    return () => {
      document.removeEventListener('keydown', escFunction, false)
    }
  }, [escFunction])

  function contactsHandler() {
    if (appContext) {
      const { isContactLayerOpen, setIsContactLayerOpen } = appContext
      setIsContactLayerOpen(!isContactLayerOpen)
    }
  }

  if (appContext) {
    const { isMenuOpen } = appContext

    return isMenuOpen ? (
      <s.Box>
        <s.Back
          onClick={() => closeMenu()}
          as={ArrowLeft}
        />
        <s.MenuList>
          <s.MenuItem
            onClick={() => closeMenu()}
            as={Link}
            to='/'>
            Home
          </s.MenuItem>
          <s.MenuItem
            isActive={checkNestedActive('products')}
            onClick={() => handleActiveNested('products')}>
            <s.Chevron as={Chevronicon} />
            Products
            <s.NestedBox>
              <Link
                onClick={() => closeMenu()}
                to='/products-aim'>
                Infrastructure Manager
              </Link>
              <Link
                onClick={() => closeMenu()}
                to='/products-avstudio'>
                AVstudio
              </Link>
              <Link
                onClick={() => closeMenu()}
                to='/products-gui'>
                Crestron HTML5 GUI
              </Link>
            </s.NestedBox>
          </s.MenuItem>
          <s.MenuItem
            onClick={() => closeMenu()}
            as={Link}
            to='/services'>
            Services
          </s.MenuItem>
          <s.MenuItem
            onClick={() => {
              window.location.href = 'https://support.avgator.com/'
            }}>
            Support
          </s.MenuItem>
          <s.MenuItem
            onClick={() => {
              contactsHandler()
            }}>
            Contacts
          </s.MenuItem>
        </s.MenuList>
      </s.Box>
    ) : null
  } else {
    return null
  }
}

export default Menu
