import React from 'react'
import Main from 'pages/main/Main'
import ProductsAim from 'pages/productsAim/ProductsAim'
import ProductsGui from 'pages/productsGui/ProductsGui'
import Services from 'pages/services/Services'
import Loading from 'pages/loading/Loading'
import { Route, Routes, useLocation } from 'react-router-dom'
import { useState, useEffect, createContext } from 'react'
import { AnimatePresence } from 'framer-motion'
import Menu from 'pages/menu/Menu'
import Partner from 'pages/partner/Partner'
import Contacts from 'pages/contacts/Contacts'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Cookie from 'components/cookie/Cookie'
import GuiList from 'pages/productsGui/GuiList'
import TemplateView from 'pages/productsGui/TemplateView'
import ReactGA from 'react-ga4'
import ProductsStudio from 'pages/productsStudio/ProductsStudio'

ReactGA.initialize('G-N0P360MYC3')

interface ContextInterface {
  isMenuOpen: boolean
  setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
  isPartnerLoginOpen: boolean
  setIsPartnerLoginOpen: React.Dispatch<React.SetStateAction<boolean>>
  isContactLayerOpen: boolean
  setIsContactLayerOpen: React.Dispatch<React.SetStateAction<boolean>>
  imagePreload: boolean
  setImagePreload: React.Dispatch<React.SetStateAction<boolean>>
}

export const AppCtx = createContext<ContextInterface | null>(null)

function App() {
  let location = useLocation()

  useEffect(() => {
    if (location.pathname) {
      ReactGA.send({ hitType: 'pageview', page: location.pathname })
    }
  }, [location.pathname])

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isPartnerLoginOpen, setIsPartnerLoginOpen] = useState(false)
  const [isContactLayerOpen, setIsContactLayerOpen] = useState(false)
  const [imagePreload, setImagePreload] = useState(true)

  useEffect(() => {
    if (!imagePreload) {
      const timer = setTimeout(() => {
        setImagePreload(true)
      }, 8000)
      return () => clearTimeout(timer)
    }
  }, [imagePreload])

  const ContextValue: ContextInterface = {
    isMenuOpen,
    setIsMenuOpen,
    isPartnerLoginOpen,
    setIsPartnerLoginOpen,
    isContactLayerOpen,
    setIsContactLayerOpen,
    imagePreload,
    setImagePreload,
  }

  return (
    <AppCtx.Provider value={ContextValue}>
      <AnimatePresence>
        {imagePreload ? null : <Loading key='loadingpage' />}
        <Routes>
          <Route
            path='/'
            element={<Main key='main-page' />}
          />
          <Route
            path='/products-aim'
            element={<ProductsAim key='products-aim' />}
          />
          <Route
            path='/products-avstudio'
            element={<ProductsStudio key='products-avstudio' />}
          />
          <Route
            path='/products-gui'
            element={<ProductsGui key='products-gui' />}>
            <Route
              index
              element={<GuiList key='products-gui-list' />}
            />
            <Route
              path=':templateName'
              element={<TemplateView key='products-gui-template' />}
            />
          </Route>
          <Route
            path='/services'
            element={<Services key='services-page' />}
          />
          <Route
            path='*'
            element={<div>No template for {location.pathname}</div>}
          />
        </Routes>
        <Menu key='menu' />
        <Partner key='partner' />
        <Contacts key='contacts' />
      </AnimatePresence>
      <ToastContainer />
      <Cookie />
    </AppCtx.Provider>
  )
}

export default App
