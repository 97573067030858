import Footer from 'components/footer/Footer'
import TopArea from 'components/toparea/TopArea'
import pic1 from 'assets/img/servicesintro.jpg'
import create from 'assets/img/slider/create.svg'
import * as s from './Styles'
import { ReactComponent as S1 } from 'assets/img/s1.svg'
import { ReactComponent as S2 } from 'assets/img/s2.svg'
import { ReactComponent as S3 } from 'assets/img/s3.svg'
import { ReactComponent as S4 } from 'assets/img/s4.svg'
import { useEffect } from 'react'

const sliderData = [
  {
    id: 1,
    word: create,
    pic: pic1,
    alt: 'Audio Visual and IT Systems',
    text: 'Services',
  },
]

const Services = () => {
  useEffect(() => {
    document.title = 'AVGATOR: Services'
  }, [])

  return (
    <>
      <TopArea $sliderData={sliderData} />
      <div className='wrap textWrap'>
        <h3>Crestron programming, GUI Development & Support </h3>

        <p>
          Our company specializes in Crestron programming and GUI Development, has Crestron Certified Programmers and
          Designers. We've been utilizing more than 24k hours annually. That is hundreds of projects annually with
          different configurations and complexity.
        </p>

        <p>
          Only our time management processes allow us to complete all projects on time; we plan at least 60 days ahead
          to provide the code before the installation process begins.
        </p>

        <p>
          Our value is people, we have enough human resources to handle projects of any size, even over a long period of
          time. We are also able to maintain 24 hours developing process.
        </p>

        <p>
          We are going to dedicate a team of professionals that will be involved during the whole developing process.We
          are utilizing project management methodology and tools, such as Microsoft project Agile etc.,(i would add
          something to that so it sounds better like “Microsoft project Agile and.. or just “.. and etc.,") to manage
          the whole developing process and bug tracker on the test and production phases.
        </p>

        <h3>Project Deliverables</h3>

        <p>Following is a complete list of all project deliverables:</p>

        <s.ServBox>
          <div>
            <s.Icon as={S1} />
            <h4>Coding and GUI development</h4>
            <p>Code preparation, Program documentation</p>
          </div>
          <div>
            <s.Icon as={S2} />
            <h4>Automatic and manual testing</h4>
            <p>Testing offline and onsite</p>
          </div>
          <div>
            <s.Icon as={S3} />
            <h4>Remote Support or Onsite Installation</h4>
            <p>Onsite installation, guides, training</p>
          </div>
          <div>
            <s.Icon as={S4} />
            <h4>Post Installation support & maintance</h4>
            <p>Remote and on-site support</p>
          </div>
        </s.ServBox>

        {/* <ul className='contentList'>
          <li>
            Coding and GUI development
            <s.Desc>Code preparation, Program documentation</s.Desc>
          </li>h4
          <li>Testing</li>
          <li>Remote Support or Onsite Installation</li>
          <li>Post Installation</li>
        </ul> */}
      </div>
      <Footer />
    </>
  )
}

export default Services
