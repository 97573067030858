import * as s from './Styles'
import Cookies from 'js-cookie'
import { useEffect, useState } from 'react'

const Cookie = () => {
  const [isConsentVisible, setIsConsentVisible] = useState(false)

  function hideBanner() {
    Cookies.set('consent', 'passed')
    setIsConsentVisible(false)
  }

  useEffect(() => {
    let isCookieExist = Cookies.get('consent')

    if (isCookieExist) {
      setIsConsentVisible(false)
    } else {
      setIsConsentVisible(true)
    }
  }, [])

  return isConsentVisible ? (
    <s.Box onClick={() => hideBanner()}>
      We collect cookies to analyze our website traffic and performance; we never collect any personal data
      <button>ok</button>
    </s.Box>
  ) : null
}

export default Cookie
