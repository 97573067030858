import * as s from './Styles'

const Tag = ({
  tagName,
  children,
  color = 'blue',
}: {
  tagName: string
  children?: string
  color?: 'blue' | 'yellow' | 'red' | 'green'
}) => {
  return (
    <s.TagBox $color={color}>
      <span>{tagName}</span>
      {children}
    </s.TagBox>
  )
}

export default Tag
