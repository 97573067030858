import styled from 'styled-components'

export const Bars = styled.svg`
  position: relative;
  width: 50px;
  height: auto;
`

export const Logo = styled.svg`
  position: relative;
  width: 150px;
  height: auto;
`

export const Box = styled.div`
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  gap: 2vh;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }: any) => theme.colors.black};
`
