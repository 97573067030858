import styled, { css, keyframes } from 'styled-components'

const Entrance = keyframes`
 0% { opacity: 0 }
 100% { opacity: 1 }
`

interface MenuProps {
  isActive?: boolean
}

export const Back = styled.svg`
  position: absolute;
  left: 5vw;
  top: 5vw;
`

export const Box = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(35, 16, 21, 0.61);
  backdrop-filter: blur(40px);
  z-index: 100;
  animation-name: ${Entrance};
  animation-duration: 0.2s;
  display: flex;
  align-items: center;
  padding-left: 20vw;
`

export const MenuList = styled.div`
  font-size: 7vmin;
  display: flex;
  flex-flow: column;
  gap: 0.3em;
  min-width: 40vw;
  a:hover {
    color: ${({ theme }: any) => theme.colors.gray};
  }
`

export const Chevron = styled.svg`
  position: absolute;
  right: calc(100% + 0.3em);
  top: 0.35em;
  width: auto;
  height: 0.3em;
  transition: 0.3s;
`

export const NestedBox = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  font-size: 0.6em;
  & > * {
    color: #fff;
    text-decoration: none;
    margin-top: 0.5em;
  }
`

export const MenuItem = styled.div<MenuProps>`
  position: relative;
  color: #fff;
  text-decoration: none;
  cursor: pointer;


  &:before {
    content: '';
    position: absolute;
    top: 1.5em;
    left: -1em;
    width: 100%;
    height: 1px;
    background-color: #fff;
    transition: 0.5s;
    transform: ${({ isActive }) => (isActive ? 'scaleX(1)' : 'scaleX(0)')};
    transform-origin: 50% 50%;
  }

  ${NestedBox} {
    overflow: hidden;
    transition: 0.5s;
    transform-origin: 50% 0;
    transform: ${({ isActive }) => (isActive ? 'scaleY(1)' : 'scaleY(0)')};
    height: ${({ isActive }) => (isActive ? 'auto' : '0')};
    padding: ${({ isActive }) => (isActive ? '1em 0' : '0')};
  }
  }

  ${Chevron} {
    transform: ${({ isActive }) =>
      isActive ? 'rotate(0deg)' : 'rotate(-90deg)'};
  }
`
