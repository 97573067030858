import styled, { keyframes } from 'styled-components'

const Entrance = keyframes`
 0% { opacity: 0 }
 100% { opacity: 1 }
`

export const Back = styled.svg`
  position: absolute;
  left: 5vw;
  top: 5vw;
`

export const Form = styled.form`
  display: block;
  width: 230px;

  & > * {
    width: 100%;
  }
`

export const Error = styled.div`
  display: block;
  font-size: 12px;
  margin-bottom: 1em;
  display: flex;
  align-items: center;
  gap: 0.5em;
`

export const Button = styled.button`
  font-size: 14px;
  padding: 1em;
  border-radius: 5px;
  border: 0;
  display: block;
  margin: 0;
  font-weight: bold;
  background: ${({ theme }: any) => theme.colors.red};
  color: #fff;
  cursor: pointer;
`

export const Box = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(35, 16, 21, 0.61);
  backdrop-filter: blur(40px);
  z-index: 100;
  animation-name: ${Entrance};
  animation-duration: 0.2s;
  display: flex;
  align-items: center;
  padding-left: 20vw;
`
