import styled from 'styled-components'

export const Box = styled.div`
  position: relative;
  background-color: #fff;
  color: ${({ theme }: any) => theme.colors.black};
  padding: 8vh 0;

  & > .wrap {
    padding-right: 8vh;
    @media screen and (max-width: 600px) {
      display: flex;
      padding-right: 0;
    }
  }
`

export const ListGroup = styled.div`
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 600px) {
    flex-flow: column;
    flex: 1;
    gap: 5vh;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;

    svg {
      width: 200px;
    }
  }
`

export const List = styled.ul`
  li {
    font-size: 14px;
    margin-bottom: 0.7em;

    a,
    span {
      color: ${({ theme }: any) => theme.colors.black};
      text-decoration: none;
      cursor: pointer;
    }
    &:first-child {
      font-size: 12px;
      font-weight: bold;
      color: ${({ theme }: any) => theme.colors.gray};
      text-transform: uppercase;
      margin-bottom: 1em;
    }
  }
`

export const Logo = styled.svg`
  position: relative;
  height: auto;
  max-width: 70px;
  margin-bottom: 0.5em;
  @media screen and (max-width: 600px) {
    margin: 0 auto;
    margin-bottom: 0.5em;
    max-width: 110px;
  }
`

export const Social = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2vh;
  max-width: 150px;
  gap: 10px;

  a {
    transition: 0.2s;
    color: ${({ theme }: any) => theme.colors.black};
    &:hover {
      color: ${({ theme }: any) => theme.colors.red};
    }
  }

  svg {
    width: auto;
    height: 2em;
  }
`

export const FooterLogoBox = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  gap: 1em;
  font-size: 12px;
`
