import { ReactNode } from 'react'
import * as s from './Styles'
import { ReactComponent as Tick } from 'assets/img/tick.svg'

const TickItem = ({ children }: { children?: ReactNode }) => {
  return (
    <s.Box>
      <Tick />
      {children}
    </s.Box>
  )
}

export default TickItem
