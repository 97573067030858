import Footer from 'components/footer/Footer';
import TopArea from 'components/toparea/TopArea';
import create from 'assets/img/slider/create.svg';
import { useEffect } from 'react';
import Countdown from 'react-countdown';
import * as s from './Styles';

const sliderData = [
  {
    id: 1,
    word: create,
    pic: (
      <iframe
        title='demo'
        src={process.env.PUBLIC_URL + 'studio/'}
        frameBorder='0'
      />
    ),
    alt: 'AVstudio',
    text: 'AVstudio',
  },
];

const ProductsStudio = () => {
  useEffect(() => {
    document.title = 'AVGATOR: AVstudio';
  }, []);

  const renderer = (props: any) => {
    if (props.completed) {
      // // Render a completed state
      return (
        <div style={{ paddingBottom: 15 }}>
          <a
            style={{ color: '#BB163F' }}
            href='https://avstudio.app/#/login'>
            AVstudio online HTML editor
          </a>
        </div>
      );
    } else {
      // Render a countdown
      return (
        <>
          <p>Next major insiders update:</p>

          <s.Countdown>
            <div>
              {props.formatted.days}
              <small>days</small>
            </div>
            :
            <div>
              {props.formatted.hours}
              <small>hours</small>
            </div>
            :
            <div>
              {props.formatted.minutes}
              <small>mins</small>
            </div>
            :
            <div>
              {props.formatted.seconds}
              <small>secs</small>
            </div>
          </s.Countdown>
        </>
      );
    }
  };

  return (
    <>
      <TopArea $sliderData={sliderData} />
      <div className='wrap textWrap'>
        <h3 id='products-aim'>AVstudio</h3>
        <p>
          Online HTML5 GUI editor for Crestron. Modern themes, rich functional.
          Zero coding skills.
        </p>
        <p>Early insiders preview available.</p>
        <Countdown
          zeroPadTime={2}
          renderer={renderer}
          date={new Date('2022-11-01')}
        />
        <hr />
        <h3 id='products-aim'>AVstudio changelog</h3>
        <p style={{ fontWeight: '600' }}>Version 0.2</p>
        <p>+ Layout and structure of Element and Container details</p>
        <div style={{ paddingLeft: 20 }}>
          <p>• Properties grouped with ability to collapse some positions</p>
          <p>• Visual simple and much more easy to use</p>
        </div>
        <p>
          + Border override for containers, can be different per display
          orientation
        </p>
        <div style={{ paddingLeft: 20 }}>
          <p>• Option to override border for any container side</p>
          <p>• Option to select different border styles</p>
          <p>
            • Rich color picker that allow get any color and opacity you want
          </p>
          <p>
            • Ability to insert copypasted color string in any format (RGB,
            RGBA, HSL and more)
          </p>
        </div>
        <p>
          + Test feature: select target container for editing by click inside
          preview
        </p>
        <div style={{ paddingLeft: 20 }}>
          <p>• Fullscreen preview current is not supported</p>
          <p>• Sometimes buggy behaviour</p>
          <p>
            • Allow pick any container/element for editing in future - keep
            informed
          </p>
        </div>
        <p>Other bugfixes and improvements.</p>
      </div>
      <Footer />
    </>
  );
};

export default ProductsStudio;
