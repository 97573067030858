import styled from 'styled-components'

export const ServBox = styled.div`
  display: block;
  margin: 2em 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 3vmax;

  h4 {
    display: block;
    min-height: 2em;
    margin-bottom: 1em;
    line-height: 120%;
  }

  @media screen and (max-width: 980px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 700px) {
    grid-template-columns: 1fr;

    & > * {
      border-bottom: 0.5px solid ${({ theme }: any) => theme.colors.red};
    }
    h4 {
      min-height: auto;
    }
  }
`
export const Icon = styled.svg`
  display: block;
  height: auto;
  width: 36px;
  margin-bottom: 10px;
`
