import * as s from './Styles';
import Footer from 'components/footer/Footer';
import { Link } from 'react-router-dom';
import { ReactComponent as LogoDecor } from 'assets/img/logo-decor.svg';
import { ReactComponent as ArrowUpRight } from 'assets/img/arrow-up-right.svg';
import { ReactComponent as Mail } from 'assets/img/mail.svg';
import TopArea from 'components/toparea/TopArea';
import pic1 from 'assets/img/slider/pic1.jpg';
import pic2 from 'assets/img/slider/pic2.jpg';
import pic3 from 'assets/img/slider/pic3.jpg';
import pic4 from 'assets/img/slider/pic4.jpg';
import ambitious from 'assets/img/slider/ambitious.svg';
import create from 'assets/img/slider/create.svg';
import solutions from 'assets/img/slider/solutions.svg';
import integration from 'assets/img/slider/integration.svg';
import ShowEmail from 'components/printMail/ShowMail';
import { useEffect } from 'react';

const productsData = [
  {
    title: 'Avgator Infrastructure Manager',
    text: 'Configuration, management and control Crestron DM NVX®, programming API for the GUI and Control Systems, Rooms and Sources Configuration, Power management, Matrix switcher etc.',
    link: '/products-aim',
  },
  {
    title: 'Crestron HTML5 GUI Template',
    text: ' HTML5 User interfaces for Crestron solutions. Crestron ONE™ for iPhone, iPad, Crestron TSW-60 and TSW/TS-70 series touch screens and PWA (Progressive Web App)',
    link: '/products-gui',
  },
  {
    title: 'Crestron Programming',
    text: ' Audio-Video Control system programming Services, Crestron, Extron, Biamp, QSC etc. Commercial and Residential    ',
    link: '/services',
  },
  {
    title: 'AVstudio',
    text: 'Online HTML5 GUI editor for Crestron. Modern themes, rich functional. Zero coding skills.',
    link: '/products-avstudio',
  },
  // {
  //   title: 'GUI Development',
  //   text: ' HTML5 User Interface templates, custom design and development, Contract Editor and database support',
  //   link: '/services',
  // },
  // {
  //   title: 'AV Support',
  //   text: ' Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco labor nisi ut aliquip ex ea commodo consequat.',
  //   link: '/services',
  // },
];

export const sliderData = [
  {
    id: 1,
    word: create,
    wordText: 'create',
    pic: pic1,
    alt: 'Audio Visual and IT Systems',
    text: 'AVGATOR design, build and create stunning bespoke Audio Visual and IT Systems in Commercial, Residential and Superyachts.',
  },
  {
    id: 2,
    word: solutions,
    wordText: 'solutions',
    pic: pic2,
    alt: 'AVGATOR solutions provider',
    text: 'As a solutions provider, AVGATOR believes there are no limits to smart systems and automation which we can design, supply, and install throughout our worldwide client portfolio.',
  },
  {
    id: 3,
    word: integration,
    wordText: 'integration',
    pic: pic3,
    alt: 'integration',
    text: 'We are commited to ensure that everyones lives are enhanced by the integration of technology.',
  },
  {
    id: 4,
    word: ambitious,
    wordText: 'ambitious',
    pic: pic4,
    alt: 'AVGATOR ambitious',
    text: 'AVGATOR is large enough to be relied on, small enough to be flexible and ambitious enough to continuously break down barriers in search for advancement and sustainability.',
  },
];

const renderProducts = productsData.map((item) => {
  return (
    <s.InfoBox key={item.title}>
      <s.CornerIcon as={ArrowUpRight} />
      <LogoDecor />
      <h3>{item.title}</h3>
      <p>{item.text}</p>
      <s.Link
        as={Link}
        to={item.link}>
        Explore
      </s.Link>
    </s.InfoBox>
  );
});

const Main = () => {
  useEffect(() => {
    document.title = 'AVGATOR';
  }, []);
  return (
    <>
      <TopArea $sliderData={sliderData} />
      <div className='wrap textWrap'>
        <h2>Products & Services</h2>
        <s.InfoSection>
          {renderProducts}
          <s.InfoBox key='mail'>
            <s.CornerIcon as={Mail} />
            <ShowEmail />
          </s.InfoBox>
        </s.InfoSection>
      </div>
      <Footer />
    </>
  );
};

export default Main;
