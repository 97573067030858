import * as s from './Styles'
import darkHuddlePic from 'assets/img/gui/darkhuddle/1.png'
import violethuddlepic from 'assets/img/gui/violethuddle/1.jpg'
import nestPic from 'assets/img/gui/brightmodernnest/1.jpg'
import brightPic from 'assets/img/gui/brightconferencespace/1.png'
import risePic from 'assets/img/gui/riseup/1.png'
import versPic from 'assets/img/gui/versailles/1.jpg'
import { ReactComponent as Arrow } from 'assets/img/arrow-up-right.svg'
import Tag from 'components/tag/Tag'
import TickItem from 'components/tickItem/TickItem'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'

interface templatesData {
  name: string
  image: string
  link: string
  tags: string[]
  features: string[]
}

const templateListData: templatesData[] = [
  {
    name: 'Dark Huddle',
    image: darkHuddlePic,
    link: 'darkhuddle',
    tags: ['CH5 Contract', 'PWA', 'WebXPanel', 'IOS', 'Android', 'Crestron ONE', 'Commercial'],
    features: [
      'High Contrast Dark Theme',
      'Demo Program Included',
      'Calendar Support',
      'Camera Control',
      'Audio Control',
      'Display Power Control',
      'Global Presets',
      'Residential Edition: YES',
      'Customisation: YES',
    ],
  },
  {
    name: 'Bright Conference Space',
    image: brightPic,
    link: 'brightconferencespace',
    tags: ['CH5 Contract', 'PWA', 'WebXPanel', 'IOS', 'Android', 'Crestron ONE', 'Commercial'],
    features: [
      'Camera Control',
      'Audio Conference',
      'Display Power Control',
      'World Clock Widget',
      'Day/Time Output Format Settings',
      'Localisations Settings',
      'Residential Edition: YES',
      'Customisation: YES',
    ],
  },
  {
    name: 'Riseup',
    image: risePic,
    link: 'riseup',
    tags: ['CH5 Contract', 'PWA', 'WebXPanel', 'IOS', 'Android', 'Crestron ONE', 'Commercial'],
    features: [
      'Calendar Support',
      'Camera Control',
      'Audio Control',
      'Display Power Control',
      'Residential Edition: YES',
      'Customisation: YES',
    ],
  },
  {
    name: 'Violet Huddle',
    image: violethuddlepic,
    link: 'violethuddle',
    tags: ['CH5 Contract', 'PWA', 'WebXPanel', 'IOS', 'Android', 'Crestron ONE', 'Commercial'],
    features: [
      'Multi Room Support',
      'Camera Control',
      'Audio Control',
      'Display Power Control',
      'Residential Edition: YES',
      'Customisation: YES',
    ],
  },
  {
    name: 'Bright Modern Nest',
    image: nestPic,
    link: 'brightmodernnest',
    tags: ['CH5 Contract', 'PWA', 'WebXPanel', 'IOS', 'Android', 'Crestron ONE', 'Residential'],
    features: [
      'Multi Room Support',
      'Multimedia Control',
      'Climate Control',
      'Lights Control',
      'Drapes Control',
      'Commercial Edition: YES',
      'Customisation: YES',
    ],
  },
  {
    name: 'Versailles',
    image: versPic,
    link: 'versailles',
    tags: ['CH5 Contract', 'PWA', 'WebXPanel', 'IOS', 'Android', 'Crestron ONE', 'Residential'],
    features: [
      'Multi Room Support',
      'Global Presets',
      'Multimedia Control',
      'Climate Control',
      'Lights Control',
      'Drapes Control',
      'Commercial Edition: YES',
      'Customisation: YES',
    ],
  },
]

const RenderTempolateList = () => {
  let out = templateListData.map((item, index) => {
    let printIndex: number | string = index + 1

    if (printIndex < 10) {
      printIndex = '0.' + printIndex
    }

    return (
      <s.TemplateBox key={item.name} id={item.link}>
        <div className='left'>
          <img src={item.image} alt={item.name} />
        </div>
        <div className='right'>
          <Link to={`${item.link}#templateGalleryId`} className='templateHeader'>
            <div className='inner'>
              <s.Arrow as={Arrow} />
              <div className='index'>{printIndex}</div>
              <h3>
                {item.name} <span>HTML5 GUI</span>
              </h3>
            </div>
          </Link>
          <div className='content'>
            <div className='contentInner'>
              <div className='tags'>
                {item.tags.map((tag) => (
                  <Tag key={item.name + '_' + tag} tagName={tag} />
                ))}
              </div>
              <div className='features'>
                {item.features.map((feature) => (
                  <TickItem key={item.name + '_' + feature}>{feature}</TickItem>
                ))}
              </div>
              <div>
                <s.goButton as={Link} to={`${item.link}#templateGalleryId`} className='goButton'>
                  explore gui
                </s.goButton>
              </div>
            </div>
          </div>
        </div>
      </s.TemplateBox>
    )
  })

  return <>{out}</>
}

const GuiList = () => {
  useEffect(() => {
    document.title = 'AVGATOR: GUI development'
  }, [])

  return (
    <>
      <h3 id='products-gui'>Crestron HTML5 GUI Development</h3>
      <p>
        We design and build HTML5 User Interface for Crestron solutions. Use Crestron ONE™ for iPhone, iPad, Crestron
        TSW-60 and TSW/TS-70 series touch screens and PWA (Progressive Web App)
      </p>
      <p>
        We deliver new emotions to our clients in interaction within modern user interface for Crestron control systems.
        New high-quality dynamic interfaces for Crestron systems, smooth and responsive control creates unforgettable
        experiences.
      </p>
      <RenderTempolateList />
    </>
  )
}

export default GuiList
