import styled, { css, keyframes } from 'styled-components'

interface IndicatorProps {
  $isActive: boolean
}

const picEntrance = keyframes`
 0% { transform: translateX(100%) }
 100% { transform: translateX(0) }
`

export const SliderSection = styled.div`
  position: relative;
  z-index: 3;
  flex: 1;
  min-height: 30vh;

  @media screen and (orientation: portrait) {
    min-height: 45vh;
  }
`

export const Slider = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

export const SliderItemImage = styled.div`
  position: relative;
  flex: 1;
  width: 40%;
  height: calc(100% - 10px);
  background-color: ${({ theme }: any) => theme.colors.black};

  iframe {
    position: relative; 
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 900px) {
    width: 100%;
    height: 40%;
  }

  &::before {
    content: '';
    position: absolute;
    z-index: 5;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border: 20px white solid;
    border-width: 5px 0 0 5px;
  }
`

export const Pic = styled.img`
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const WordText = styled.div`
  position: absolute;
  bottom: -0.1em;
  left: -0.05em;
  z-index: 6;
  transform: translateX(100%);
  color: ${({ theme }: any) => theme.colors.red};
  color: #fff;
  font-weight: bold;
  font-size: max(7vw, 50px);
  line-height: 90%;
`

export const SliderItemContent = styled.div`
  position: relative;
  flex: 1;
  width: 60%;
  display: flex;
  flex-flow: column;
  padding: 0 2.5vw;
  overflow: hidden;
  @media screen and (max-width: 900px) {
    width: 100%;
    flex: 2;
  }
`

export const SliderText = styled.div`
  flex: 1;
  display: flex;
  flex-flow: column;
  justify-content: center;
  gap: 3vh;

  color: ${({ theme }: any) => theme.colors.black};

  p {
    color: #fff;
    font-size: max(2.5vmax, 20px);
    font-family: ${({ theme }: any) => theme.fonts.custom};
    font-weight: normal;
    line-height: 120%;
  }
`

export const SlideItem = styled.div<IndicatorProps>`
  position: absolute;
  min-width: 100%;
  height: 100%;
  display: flex;
  will-change: all;

  @media screen and (max-width: 900px) {
    flex-flow: column;
  }

  ${Pic} {
    transition: 0.5s;
    z-index: 1;
    opacity: 0;
  }

  ${WordText} {
    opacity: 0;
    transition: 0.5s;
  }

  ${SliderText} {
    opacity: 0;
    transform: translateX(150%);
    transition: 0.7s;
  }

  ${({ $isActive }) =>
    $isActive &&
    css`
      ${Pic} {
        z-index: 2;
        /* animation-name: ${picEntrance};
        animation-duration: 1s; */
        opacity: 1;
      }
      ${WordText} {
        opacity: 0.999;
        transform: translateX(0);
      }
      ${SliderText} {
        opacity: 1;
        transform: translateX(0);
      }
    `}
`

export const SliderControls = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 50%;
  z-index: 10;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 900px) {
    width: 97%;
  }
`

export const ArrowRight = styled.svg`
  position: relative;
  transform: scaleX(-1);
`

export const ArrowBox = styled.div`
  display: flex;
  gap: 10px;

  & > * {
    cursor: pointer;
  }
`

export const SliderIndicators = styled.div`
  display: flex;
  gap: 2vw;
  padding-right: 10%;
`

export const Indicator = styled.div<IndicatorProps>`
  width: max(3.5vw, 40px);
  height: 2px;
  transition: 0.5s;
  background-color: ${({ $isActive }) =>
    $isActive ? '#fff' : 'rgba(255, 255, 255, 0.52)'};
`
