import * as s from './Styles'
import { ReactComponent as ArrowLeft } from 'assets/img/arrow-left-circle.svg'
import { useContext, useState } from 'react'
import { AppCtx } from 'App'
import { useEffect, useCallback } from 'react'
import ShowEmail from 'components/printMail/ShowMail'

const Contacts = () => {
  const appContext = useContext(AppCtx)

  const escFunction = useCallback(
    (event) => {
      if (event.key === 'Escape') {
        if (appContext) {
          const { setIsContactLayerOpen } = appContext
          setIsContactLayerOpen(false)
        }
      }
    },
    [appContext]
  )

  const [pageTitle, setPageTitle] = useState('')

  useEffect(() => {
    if (appContext?.isContactLayerOpen) {
      if (!pageTitle) {
        setPageTitle(document.title)
      }
      document.title = 'AVGATOR: Contacts'
    } else {
      if (pageTitle) {
        document.title = pageTitle
        setPageTitle('')
      }
    }
  }, [appContext, pageTitle])

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false)
    return () => {
      document.removeEventListener('keydown', escFunction, false)
    }
  }, [escFunction])

  if (appContext) {
    const { isContactLayerOpen, setIsContactLayerOpen } = appContext

    return isContactLayerOpen ? (
      <s.Box>
        <s.Back onClick={() => setIsContactLayerOpen(false)} as={ArrowLeft} />
        <div>
          <ShowEmail />
          <s.Address>9716 Rea Rd, Ste B#1186, Charlotte NC, 28277</s.Address>
        </div>
      </s.Box>
    ) : null
  } else {
    return null
  }
}

export default Contacts
