import styled from 'styled-components'




export const Box = styled.div`
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.6);
  font-size: 11px;
  text-align: center;
  padding: 1em;

  button {
    border: 0;
    background-color: ${({ theme }: any) => theme.colors.red};
    color: #fff;
    border-radius: 3px;
    text-transform: uppercase;
    font-size: 10px;
    padding: 3px 5px;
    cursor: pointer;
    margin-left: 1em;
    display: inline-block;
  }

`
