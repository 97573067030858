import styled from 'styled-components'

export const InfoSection = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3vw;
  padding-bottom: 3vw;
`

export const CarouselBox = styled.div`
  margin-bottom: 2em;
 

  .image-gallery-swipe {
    border-radius: 15px;
    overflow: hidden;
  }

  .image-gallery-thumbnails-container button {
    border-radius: 15px;
    overflow: hidden;
  }
`

export const InfoBox = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  min-height: 60vh;
  gap: 3vh;
  text-align: center;
  padding: 3vw;
  background: linear-gradient(139.62deg, #3c3c3c -2.28%, rgba(46, 64, 81, 0.32) 37.1%);

  p {
    font-size: 16px;
    font-family: ${({ theme }: any) => theme.fonts.default};
    line-height: 150%;
  }
`

export const CornerIcon = styled.svg`
  position: absolute;
  z-index: 3;
  right: 8px;
  top: 8px;
  width: 20px;
  height: auto;
`
export const Link = styled.a`
  position: absolute;
  z-index: 3;
  right: 0;
  bottom: 0;
  width: 170px;
  background-color: ${({ theme }: any) => theme.colors.red};
  color: #fff;
  padding: 1.3em 0;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  border-radius: 5px 0 0 0;
`

export const Table = styled.table`
  th {
    font-weight: bold;
    @media screen and (max-width: 600px) {
      font-size: 0.8em;
    }
    &:first-child {
      text-align: left;
      color: ${({ theme }: any) => theme.colors.gray};
      width: 100%;
    }
  }
  td {
    &:first-child {
      text-align: left;
      padding-left: 1em;
    }
  }
`

export const ImagesRow = styled.div`
  display: flex;
  gap: 2vw;

  @media screen and (max-width: 900px) {
    flex-flow: column;
  }
`

export const TemplateBox = styled.div`
  position: relative;
  margin-bottom: 7vh;

  @media (max-width: 900px) {
    padding-top: 45vh;
  }

  & * {
    min-width: 0;
  }

  .left {
    position: absolute;
    left: 0;
    top: 0;
    width: 70%;
    height: 100%;
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 0 0;
    }

    @media (max-width: 900px) {
      width: 100%;
      height: 45vh;
    }
  }
  .right {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
  }

  .templateHeader {
    text-decoration: none;
    position: relative;
    cursor: pointer;
    margin-top: 5vh;
    padding-left: 65%;
    @media (max-width: 900px) {
      margin-top: 0;
      padding-left: 0;
    }
    .index {
      font-size: 2rem;
      line-height: 100%;
      color: #fff;
    }
    h3 {
      font-size: 2.5rem;
      margin-bottom: 0.5em;
      line-height: 100%;

      span {
        margin-top: 1.5em;
        display: block;
        line-height: 100%;
        font-size: 0.4em;
        font-weight: normal;
      }
    }

    .inner {
      position: relative;
      padding: 1rem;
      top: 0;
      right: 0;
      min-width: min(50vw, 800px);
      /* background-color: ${({ theme }: any) => theme.colors.red}; */
      background: rgba(187, 22, 63, 0.88);
      backdrop-filter: blur(4px);
    }
  }

  .content {
    position: relative;
    padding-left: 70%;
    flex: 1;
    @media (max-width: 900px) {
      padding-left: 0;
    }
  }

  .contentInner {
    padding: 2rem 0 0 2rem;
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    gap: 2rem;
    @media (max-width: 900px) {
      padding-left: 0;
    }
  }

  .tags {
    display: flex;
    flex-wrap: wrap;
    gap: 0 0.5rem;
  }

  .features {
    display: flex;
    flex-flow: column;
    gap: 1rem;
  }
`

export const Arrow = styled.svg`
  position: absolute;
  right: 10px;
  top: 10px;
`

export const goButton = styled.button`
  display: block;
  text-decoration: none;
  position: relative;
  width: 100%;
  border: 2px solid ${({ theme }: any) => theme.colors.red};
  text-align: center;
  color: #fff;
  background: none;
  text-transform: uppercase;
  font-size: 0.9rem;
  padding: 1.2em 0;
  cursor: pointer;
  transition: 300ms;
  &:hover {
    background-color: ${({ theme }: any) => theme.colors.red};
  }
`

export const BackBox = styled.div`
  position: relative;
  margin-bottom: 2rem;

  @media (min-width: 600px) {
    max-width: 400px;
  }
`


export const Ipad = styled.div`
  width:1000px;
  height: 700px;
  position: relative;
  margin-bottom: 5vh;

  @media (max-width:1050px) {
    width:750px;
  height: 900px;
  }

  @media (max-width: 800px) {
    display: none;
  }

  .ipad-frame {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #818181;
    border-radius: 30px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
    background-color: #262626;
    background: conic-gradient(from 35.08deg at 50% 50%, #1E1E1E 0deg, rgba(39, 39, 39, 0) 360deg), #262626;
    padding: 35px;




    iframe {
      position: relative;
      width: 100%;
      height: 100%;
      background-color: #000;
      border-radius: 6px;
      border: 1px solid rgba(255, 255, 255, 0.35);
    }
  }
`

export const DemoBox = styled.div`
margin-bottom: 5vh;
  @media (min-width: 800px) {
    display: none;
  }
`

export const DemoTitle = styled.div`
display: flex;
align-items: center;
gap: 1em;
margin-bottom: 1.5em;

h3 {
  padding-bottom: 0;
  margin-bottom: 0;
}

@media (max-width: 800px) {
    display: block;

    h3 {
      margin-bottom: 1em;
    }
  }
`

export const TubeLink = styled.a`
display: flex;
align-items: center;
color: #FF3D00;
cursor: pointer;
text-decoration: none;

&:hover {
  text-decoration: underline;
}

svg {
  height: 2.5em;
  width: auto;
}
`
