import styled, { css } from 'styled-components'

interface RoboProps {
  roboCheckState: boolean
}

export const NotRobotBox = styled.div<RoboProps>`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    margin-right: 0.5em;
  }

  span {
    display: block;
    position: relative;
    user-select: none;

    &:after {
      content: '';
      display: block;
      position: relative;
      margin-top: 5px;
      top: 100%;
      width: 100%;
      border-bottom: 0.5px solid ${({ theme }: any) => theme.colors.gray};
    }
  }

  .checkMark {
    opacity: 0;
    transform: scale(0);
    transition: 0.2s;
  }

  &:hover {
    svg {
      color: ${({ theme }: any) => theme.colors.green};
    }
  }

  ${({ roboCheckState }) =>
    roboCheckState &&
    css`
      svg {
        color: ${({ theme }: any) => theme.colors.green};
      }
      .checkMark {
        opacity: 1;
        transform: scale(1);
      }
    `}
`
