import styled from 'styled-components'

interface Props {
  $color: 'blue' | 'yellow' | 'red' | 'green'
}

export const TagBox = styled.div<Props>`
  position: relative;
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: 0.8em;

  span {
    display: block;
    padding: 0.3em;
    border-radius: 5px;
    border: 1px solid ${({ theme, $color }: any) => theme.colors[$color]};
    margin-right: 0.3em;
    color: #fff;
    font-size: 16px;
    min-width: 3em;
    text-align: center;
  }
`
