import { ReactComponent as Logo } from 'assets/img/logo.svg'
import { ReactComponent as Bars } from 'assets/img/bars.svg'
import * as s from './Styles'
import { motion } from 'framer-motion'
import React from 'react'

const Loading = () => {
  const LogoRef = React.forwardRef<SVGSVGElement>((props, ref) => (
    <Logo {...props} ref={ref} />
  ))
  const MotionLogo = motion(LogoRef)

  const BarsRef = React.forwardRef<SVGSVGElement>((props, ref) => (
    <Bars {...props} ref={ref} />
  ))
  const MotionBars = motion(BarsRef)

  return (
    <s.Box>
      <s.Logo
        as={MotionLogo}
        transition={{ type: 'spring', duration: 1 }}
        initial={{ opacity: 0, y: -100 }}
        animate={{ opacity: 1, y: -0.01 }}
        exit={{ opacity: 0, y: -100, transition: { duration: 0.3 } }}
      />
      <s.Bars
        as={MotionBars}
        transition={{ type: 'spring', duration: 1 }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0, y: 100, transition: { duration: 0.3 } }}
      />
    </s.Box>
  )
}

export default Loading
