import Footer from 'components/footer/Footer'
import TopArea from 'components/toparea/TopArea'
import pic1 from 'assets/img/gui.jpg'
import create from 'assets/img/slider/create.svg'
import { Outlet } from 'react-router-dom'

const sliderData = [
  {
    id: 1,
    word: create,
    pic: pic1,
    alt: 'Audio Visual and IT Systems',
    text: 'HTML5 GUI',
  },
]

const ProductsGui = () => {
  return (
    <>
      <TopArea $sliderData={sliderData} />
      <div className='wrap textWrap'>
        <Outlet />
      </div>
      <Footer />
    </>
  )
}

export default ProductsGui
