import * as s from './Styles'
import Headerline from 'components/headerline/Headerline'
import { ReactComponent as Dots } from 'assets/img/dots.svg'
import Slider from 'components/slider/Slider'
import { SliderProps } from 'components/slider/Slider'

const TopArea = ({ $sliderData }: { $sliderData: Array<SliderProps> }) => {
  let fullheight = false

  if ($sliderData.length > 1) {
    fullheight = true
  }

  return (
    <>
      <div className={`wrap ${fullheight && 'fullheight'}`}>
        <s.Top $fullheight={fullheight}>
          <Headerline />
          <Slider $sliderData={$sliderData} />
          <s.Dots $compact={!fullheight} as={Dots} />
        </s.Top>
      </div>
    </>
  )
}

export default TopArea
