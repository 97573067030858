import styled, { keyframes } from 'styled-components'

const Entrance = keyframes`
 0% { opacity: 0 }
 100% { opacity: 1 }
`

export const Back = styled.svg`
  position: absolute;
  left: 5vw;
  top: 5vw;
`

export const Address = styled.div`
  position: relative;
  margin-top: 4em;
  font-size: 14px;
  padding-top: 1em;

  &:after {
    content: '';
    position: absolute;
    width: 50px;
    height: 1px;
    background-color: #fff;
    left: 0;
    top: 0;
  }
`

export const Box = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(35, 16, 21, 0.61);
  backdrop-filter: blur(40px);
  z-index: 100;
  animation-name: ${Entrance};
  animation-duration: 0.2s;
  display: flex;
  align-items: center;
  padding-left: 20vw;
`
