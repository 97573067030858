import styled, { css, keyframes } from 'styled-components'
import darkbg from 'assets/img/darkbg.svg'
import linesbg from 'assets/img/linesbg.svg'

interface TopProps {
  $fullheight?: any
}

interface DotsProps {
  $compact?: boolean
}

export const Top = styled.div<TopProps>`
  position: relative;
  min-height: ${({ $fullheight }) => ($fullheight ? '96vh' : 'auto')};
  display: flex;
  flex-flow: column;
  margin-bottom: ${({ $fullheight }) => ($fullheight ? '10vh' : '6vh')};

  @media screen and (max-width: 900px) {
    margin-bottom: 5vh;
  }

  &::before {
    content: '';
    position: absolute;
    z-index: 2;
    right: 0;
    bottom: 0;
    height: 100%;
    width: ${({ theme }: any) => theme.rightSliderWidth};
    background-image: url(${darkbg}), url(${linesbg});
    background-position: 100% 0, -10vw -3vh;
    background-size: 60% auto, auto 130%;
    background-repeat: no-repeat;
    background-color: ${({ theme }: any) => theme.colors.red};

    @media screen and (max-width: 900px) {
      width: 100%;
      top: 30%;
      height: 70%;
    }
  }
`

export const Dots = styled.svg<DotsProps>`
  position: absolute;
  z-index: 5;
  left: 0;
  bottom: 0;
  transform: ${({ $compact }) =>
    $compact ? 'translate(-18%, 30%)' : 'translate(-18%, 80%)'};
  height: auto;
  width: 15vw;

  @media screen and (max-width: 900px) {
    display: none;
  }
`

export const InfoSection = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3vw;
  padding-bottom: 3vw;
`

export const InfoBox = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  min-height: 60vh;
  gap: 3vh;
  text-align: center;
  padding: 3vw;
  background: linear-gradient(
    139.62deg,
    #3c3c3c -2.28%,
    rgba(46, 64, 81, 0.32) 37.1%
  );

  p {
    font-size: 16px;
    font-family: ${({ theme }: any) => theme.fonts.default};
    line-height: 150%;
  }
`

export const CornerIcon = styled.svg`
  position: absolute;
  z-index: 3;
  right: 8px;
  top: 8px;
  width: 20px;
  height: auto;
`
export const Link = styled.a`
  position: absolute;
  z-index: 3;
  right: 0;
  bottom: 0;
  width: 170px;
  background-color: ${({ theme }: any) => theme.colors.red};
  color: #fff;
  padding: 1.3em 0;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  border-radius: 5px 0 0 0;
`
