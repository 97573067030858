import * as s from './Styles'
import ImageGallery from 'react-image-gallery'
import { useParams, useNavigate } from 'react-router'
import { ReactComponent as Tube } from 'assets/img/tube.svg'
import { useEffect } from 'react'
import { toast } from 'react-toastify'

const importAll = (require: any) =>
  require.keys().reduce((acc: any, next: string) => {
    acc[next.replace('./', '')] = require(next)
    return acc
  }, {})

function getTemplateData(templateFolder: string) {
  let allImg
  let name = ''
  let ipadDemo = ''
  let tubeLink = ''

  switch (templateFolder) {
    case 'versailles':
      allImg = importAll(require['context']('assets/img/gui/versailles', false, /\.(png|jpe?g|svg)$/))
      name = 'Versailles'
      break
    case 'riseup':
      allImg = importAll(require['context']('assets/img/gui/riseup', false, /\.(png|jpe?g|svg)$/))
      name = 'Riseup'
      break

    case 'darkhuddle':
      allImg = importAll(require['context']('assets/img/gui/darkhuddle', false, /\.(png|jpe?g|svg)$/))
      name = 'Dark Huddle'
      ipadDemo = '/huddle_demo/'
      break

    case 'brightconferencespace':
      allImg = importAll(require['context']('assets/img/gui/brightconferencespace', false, /\.(png|jpe?g|svg)$/))
      name = 'Bright Conference Space'
      ipadDemo = '/bright_demo/'
      tubeLink = 'https://youtu.be/WyHKh8WJwQw'
      break

    case 'violethuddle':
      allImg = importAll(require['context']('assets/img/gui/violethuddle', false, /\.(png|jpe?g|svg)$/))
      name = 'Violet Huddle'
      break

    case 'brightmodernnest':
      allImg = importAll(require['context']('assets/img/gui/brightmodernnest', false, /\.(png|jpe?g|svg)$/))
      name = 'Bright Modern Nest'
      break

    default:
      break
  }

  var a = []

  if (allImg) {
    for (var i = 0; i < Object.keys(allImg).length / 2; i++) {
      let img = Object.values(allImg)[i]
      a.push({
        original: img,
        thumbnail: img,
      })
    }
  }

  let out = { images: a, name, ipadDemo, tubeLink }

  return out
}

const TemplateView = () => {
  const { templateName } = useParams()
  const navigate = useNavigate()

  let { images, name, ipadDemo, tubeLink } = getTemplateData(templateName || '')

  if (ipadDemo) {
    toast.info("Live Template Demo can't represent full logic on website due to Crestron Processor inaccessibility.")
  }

  useEffect(() => {
    document.title = `AVGATOR: GUI ${name}`
  }, [name])

  const BackButton = () => {
    return (
      <s.BackBox>
        <s.goButton
          onClick={() => navigate(`/products-gui#${templateName}`)}
          className='goButton'>
          &#8592; Back to templates
        </s.goButton>
      </s.BackBox>
    )
  }

  console.log(process.env.PUBLIC_URL + ipadDemo)

  return templateName ? (
    <div id='templateGalleryId'>
      <BackButton />
      {ipadDemo && (
        <>
          <s.DemoTitle>
            <h3>{name} LIVE DEMO</h3>
            {tubeLink && (
              <s.TubeLink
                target='_blank'
                href={tubeLink}>
                <Tube />
                Watch on Youtube
              </s.TubeLink>
            )}
          </s.DemoTitle>

          <s.Ipad>
            <div className='ipad-frame'>
              <iframe
                title='demo'
                src={process.env.PUBLIC_URL + ipadDemo}
                frameBorder='0'
              />
            </div>
          </s.Ipad>

          <s.DemoBox>Live Demo available only on wide screens for better expierence.</s.DemoBox>
        </>
      )}

      <h3>{name} HTML5 GUI</h3>
      <s.CarouselBox>
        <ImageGallery
          lazyLoad={false}
          showFullscreenButton
          items={images}
        />
      </s.CarouselBox>
      <BackButton />
    </div>
  ) : null
}

export default TemplateView
