import * as s from './Styles'
import Logo from 'components/logo/Logo'
import { Link } from 'react-router-dom'
import { useContext } from 'react'
import { AppCtx } from 'App'
import { ReactComponent as LinkedIn } from 'assets/img/linkedin.svg'
import { ReactComponent as Facebook } from 'assets/img/facebook.svg'
import { ReactComponent as Twitter } from 'assets/img/twitter.svg'
import { ReactComponent as Instagram } from 'assets/img/instagram.svg'
import { ReactComponent as Telegram } from 'assets/img/telegram.svg'

const Footer = () => {
  const appContext = useContext(AppCtx)

  function partnerHandler() {
    if (appContext) {
      const { isPartnerLoginOpen, setIsPartnerLoginOpen } = appContext
      setIsPartnerLoginOpen(!isPartnerLoginOpen)
    }
  }

  function contactsHandler() {
    if (appContext) {
      const { isContactLayerOpen, setIsContactLayerOpen } = appContext
      setIsContactLayerOpen(!isContactLayerOpen)
    }
  }

  return (
    <s.Box>
      <div className='wrap'>
        <s.ListGroup>
          <s.FooterLogoBox>
            <s.Logo
              as={Logo}
              dark
            />
            <div>©AVGATOR INC</div>
            <div>Charlotte NC, 28277</div>
            <s.Social>
              <a
                href='https://www.linkedin.com/company/avgator'
                target='_blank'
                rel='noreferrer'>
                <LinkedIn />
              </a>

              <a
                href='https://www.facebook.com/avgator'
                target='_blank'
                rel='noreferrer'>
                <Facebook />
              </a>

              <a
                href='https://twitter.com/AVgator_inc'
                target='_blank'
                rel='noreferrer'>
                <Twitter />
              </a>

              <a
                href='https://www.instagram.com/avgator/'
                target='_blank'
                rel='noreferrer'>
                <Instagram />
              </a>

              <a
                href='https://t.me/AVgator'
                target='_blank'
                rel='noreferrer'>
                <Telegram />
              </a>
            </s.Social>
          </s.FooterLogoBox>
          <s.List>
            <li>Products & Services</li>
            <li>
              <Link to='/products-aim'>Infrastructure Manager</Link>
            </li>
            <li>
              <Link to='/products-avstudio'>AVstudio</Link>
            </li>
            <li>
              <Link to='/products-gui'>Crestron HTML5 GUI</Link>
            </li>
            <li>
              <Link to='/services'>Services</Link>
            </li>
          </s.List>
          <s.List>
            <li>Links</li>
            <li>
              <span onClick={() => (window.location.href = 'https://support.avgator.com/')}>Support</span>
            </li>
            <li>
              <span onClick={() => partnerHandler()}>Partner login</span>
            </li>
            <li>
              <span onClick={() => contactsHandler()}>Contacts</span>
            </li>
          </s.List>
        </s.ListGroup>
      </div>
    </s.Box>
  )
}

export default Footer
