import * as s from './Styles'
import { ReactComponent as LogoDecor } from 'assets/img/logo-decor.svg'
import { ReactComponent as ArrowLeft } from 'assets/img/arrow-left-circle.svg'
import React, { useState, useEffect, useCallback } from 'react'
import { AppCtx } from 'App'
import { useContext } from 'react'

export interface SliderProps {
  id: number
  pic: string | React.ReactNode
  alt: string
  word: any
  wordText?: string
  text: string
}

const Slider = ({ $sliderData }: { $sliderData: Array<SliderProps> }) => {
  const sliderCount = $sliderData.length

  let staticSlider = false

  if (sliderCount === 1) {
    staticSlider = true
  }

  const appContext = useContext(AppCtx)

  const [activeSlide, setActiveSlide] = useState(1)
  const [allowAutoSlide, setAllowAutoSlide] = useState(true)
  const [imagesCompleted, setImagesCompleted] = useState<number>(0)

  useEffect(() => {
    if (!staticSlider) {
      appContext?.setImagePreload(false)
    }
  }, [appContext, staticSlider])

  useEffect(() => {
    if (imagesCompleted === sliderCount) {
      appContext?.setImagePreload(true)
    }
  }, [appContext, imagesCompleted, sliderCount])

  const renderSliderItems = $sliderData.map((item) => {
    let isActive = false
    if (item.id === activeSlide) {
      isActive = true
    }

    return (
      <s.SlideItem
        $isActive={isActive}
        id={`slide-${item.id}`}
        key={item.id}>
        <s.SliderItemImage key={item.id}>
          {typeof item.pic === 'string' ? (
            <s.Pic
              id={item.wordText}
              onLoad={() => setImagesCompleted((prevstate) => prevstate + 1)}
              src={item.pic}
              alt={item.alt}
            />
          ) : (
            item.pic
          )}

          {!staticSlider && <s.WordText>{item.wordText} </s.WordText>}
        </s.SliderItemImage>
        <s.SliderItemContent
          onMouseEnter={() => setAllowAutoSlide(false)}
          onMouseLeave={() => setAllowAutoSlide(true)}>
          <s.SliderText>
            <LogoDecor />
            {staticSlider ? <h2>{item.text}</h2> : <p>{item.text}</p>}
          </s.SliderText>
        </s.SliderItemContent>
      </s.SlideItem>
    )
  })

  const renderSliderIndicator = $sliderData.map((item) => {
    let isActive = false
    if (item.id === activeSlide) {
      isActive = true
    }

    return (
      <s.Indicator
        key={item.id}
        $isActive={isActive}
      />
    )
  })

  const changeSlide = useCallback(
    (operator: 'plus' | 'minus') => {
      if (operator === 'plus') {
        if (activeSlide === sliderCount) {
          setActiveSlide(1)
        } else {
          setActiveSlide(activeSlide + 1)
        }
      } else if (operator === 'minus') {
        if (activeSlide !== 1) {
          setActiveSlide(activeSlide - 1)
        } else {
          setActiveSlide(sliderCount)
        }
      }
    },
    [activeSlide, sliderCount]
  )

  useEffect(() => {
    const interval = setInterval(() => {
      if (allowAutoSlide && !staticSlider) {
        changeSlide('plus')
      }
    }, 3500)
    return () => clearInterval(interval)
  }, [allowAutoSlide, changeSlide, sliderCount, staticSlider])

  return (
    <>
      <s.SliderSection>
        <s.Slider>{renderSliderItems}</s.Slider>
        {!staticSlider && (
          <s.SliderControls
            onMouseEnter={() => setAllowAutoSlide(false)}
            onMouseLeave={() => setAllowAutoSlide(true)}>
            <s.ArrowBox>
              <ArrowLeft onClick={() => changeSlide('minus')} />
              <s.ArrowRight
                onClick={() => changeSlide('plus')}
                as={ArrowLeft}
              />
            </s.ArrowBox>
            <s.SliderIndicators>{renderSliderIndicator}</s.SliderIndicators>
          </s.SliderControls>
        )}
      </s.SliderSection>
    </>
  )
}

export default Slider
