import styled, { css } from 'styled-components'

interface Props {
  $isActive: boolean
}

export const AccordeonHeading = styled.h4`
  position: relative;
  cursor: pointer;
`
export const AccordeonContent = styled.div`
  position: relative;
`

export const AccordeonItem = styled.div<Props>`
  position: relative;
  padding: 1em;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  margin-bottom: 1em;

  ${({ $isActive }) =>
    !$isActive &&
    css`
      &:hover {
        border-color: ${({ theme }: any) => theme.colors.blue};
      }
    `}

  ${AccordeonContent} {
    display: ${({ $isActive }) => ($isActive ? 'block' : 'none')};
  }
`

export const ClickArea = styled.div`
  cursor: pointer;
`

export const InfoSection = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3vw;
  padding-bottom: 3vw;
`

export const CarouselBox = styled.div`
  margin-bottom: 2em;
`

export const InfoBox = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  min-height: 60vh;
  gap: 3vh;
  text-align: center;
  padding: 3vw;
  background: linear-gradient(
    139.62deg,
    #3c3c3c -2.28%,
    rgba(46, 64, 81, 0.32) 37.1%
  );

  p {
    font-size: 16px;
    font-family: ${({ theme }: any) => theme.fonts.default};
    line-height: 150%;
  }
`

export const CornerIcon = styled.svg`
  position: absolute;
  z-index: 3;
  right: 8px;
  top: 8px;
  width: 20px;
  height: auto;
`
export const Link = styled.a`
  position: absolute;
  z-index: 3;
  right: 0;
  bottom: 0;
  width: 170px;
  background-color: ${({ theme }: any) => theme.colors.red};
  color: #fff;
  padding: 1.3em 0;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  border-radius: 5px 0 0 0;
`

export const Table = styled.table`
  th {
    font-weight: bold;
    @media screen and (max-width: 600px) {
      font-size: 0.8em;
    }
    &:first-child {
      text-align: left;
      color: ${({ theme }: any) => theme.colors.gray};
      width: 100%;
    }
  }
  td {
    &:first-child {
      text-align: left;
      padding-left: 1em;
    }
  }
`

export const ImagesRow = styled.div`
  display: flex;
  gap: 2vw;

  @media screen and (max-width: 900px) {
    flex-flow: column;
  }
`
