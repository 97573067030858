import * as s from './Styles'
import { ReactComponent as ArrowLeft } from 'assets/img/arrow-left-circle.svg'
import { ReactComponent as AlectIcon } from 'assets/img/alert-circle.svg'
import { useContext } from 'react'
import { AppCtx } from 'App'
import { useEffect, useCallback, useState } from 'react'

const Partner = () => {
  const appContext = useContext(AppCtx)

  const [isErrorVisible, setIsErrorVisible] = useState(false)

  const escFunction = useCallback(
    (event) => {
      if (event.key === 'Escape') {
        if (appContext) {
          const { setIsPartnerLoginOpen } = appContext

          setIsPartnerLoginOpen(false)
        }
      }
    },
    [appContext]
  )

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false)

    return () => {
      document.removeEventListener('keydown', escFunction, false)
    }
  }, [escFunction])

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    ;(e.target as HTMLFormElement).reset()
    setIsErrorVisible(true)
  }

  const [pageTitle, setPageTitle] = useState('')

  useEffect(() => {
    if (appContext?.isPartnerLoginOpen) {
      setIsErrorVisible(false)
      if (!pageTitle) {
        setPageTitle(document.title)
      }

      document.title = 'AVGATOR: Partner Login'
    } else {
      if (pageTitle) {
        document.title = pageTitle
        setPageTitle('')
      }
    }
  }, [appContext, pageTitle])

  if (appContext) {
    const { isPartnerLoginOpen, setIsPartnerLoginOpen } = appContext

    return isPartnerLoginOpen ? (
      <s.Box>
        <s.Back onClick={() => setIsPartnerLoginOpen(false)} as={ArrowLeft} />
        <s.Form onSubmit={(e) => handleSubmit(e)}>
          <h3>Partner login</h3>
          {isErrorVisible && (
            <s.Error>
              <AlectIcon /> Email and/or password incorrect
            </s.Error>
          )}
          <input required placeholder='email' type='email' />
          <input required placeholder='password' type='password' />
          <s.Button type='submit'>Log In</s.Button>
        </s.Form>
      </s.Box>
    ) : null
  } else {
    return null
  }
}

export default Partner
