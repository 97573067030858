import Footer from 'components/footer/Footer';
import TopArea from 'components/toparea/TopArea';
import pic1 from 'assets/img/aim.jpg';
import create from 'assets/img/slider/create.svg';
import * as s from './Styles';
import { ReactComponent as CheckCircle } from 'assets/img/check-circle.svg';
import { ReactComponent as Cross } from 'assets/img/cross.svg';
import { useState, useEffect } from 'react';
import ImageGallery from 'react-image-gallery';
import ReactJson from 'react-json-view';
import Tag from 'components/tag/Tag';
// import Pricing from 'components/pricing/Pricing'

function importAll(r: any) {
  let images: any = {};
  r.keys().map((item: any, index: any) => {
    images[item.replace('./', '')] = r(item);
  });
  return images;
}

const allImg = importAll(
  require['context']('assets/img/aim', false, /\.(png|jpe?g|svg)$/)
);

function images() {
  var a = [];
  for (var i = 1; i < 35; i++) {
    let img = allImg[`${i}.png`];
    a.push({
      original: img,
      thumbnail: img,
      // originalWidth: '1928px',
      // originalHeight: '1088px',
    });
  }
  return a;
}

const sliderData = [
  {
    id: 1,
    word: create,
    pic: pic1,
    alt: 'Audio Visual and IT Systems',
    text: 'AIM Web App',
  },
];

const comparisonTableData = [
  {
    feature: 'Multicast address plan',
    derector: true,
    aim: true,
  },
  {
    feature: 'NVX Discovery',
    derector: true,
    aim: true,
  },
  {
    feature: 'DM-NVX Endpoints Advanced configuration',
    derector: false,
    aim: true,
  },
  {
    feature: 'Virtual IO Assignment',
    derector: true,
    aim: true,
  },
  {
    feature: 'Matrix routing',
    derector: true,
    aim: true,
  },
  {
    feature: 'Streaming info',
    derector: true,
    aim: true,
  },
  {
    feature: 'Streaming Advanced Filtering',
    derector: false,
    aim: true,
  },

  {
    feature: 'Source List',
    derector: false,
    aim: true,
  },
  {
    feature: 'Room List',
    derector: false,
    aim: true,
  },
  {
    feature: 'Multi-language Labels',
    derector: false,
    aim: true,
  },
  {
    feature: 'Source assignment matrix',
    derector: false,
    aim: true,
  },
  {
    feature: 'Favorites',
    derector: false,
    aim: true,
  },
  {
    feature: 'Processors Control',
    derector: false,
    aim: true,
  },
  {
    feature: 'GUI Update Control',
    derector: false,
    aim: true,
  },
  {
    feature: 'Crestron One config file generator',
    derector: false,
    aim: true,
  },

  {
    feature: 'UAC (User Access Control)',
    derector: false,
    aim: true,
  },
  {
    feature: 'PDU Control (SNMP/TCP Command Protocol)',
    derector: false,
    aim: true,
  },
  {
    feature: 'JSON API',
    derector: false,
    aim: true,
  },
  {
    feature: 'HTML5 GUI Supports',
    derector: false,
    aim: true,
  },
  {
    feature: 'Additional functionality on request',
    derector: false,
    aim: true,
  },
];

const FullTable = () => {
  const out = comparisonTableData.map((item) => {
    return (
      <tr key={item.feature}>
        <td>{item.feature}</td>
        <td>{item.derector ? <CheckCircle /> : <Cross />}</td>
        <td>{item.aim ? <CheckCircle /> : <Cross />}</td>
      </tr>
    );
  });

  const [compactView, setCompactView] = useState(false);

  function compactSetter() {
    if (window.matchMedia('(max-width: 800px)').matches) {
      setCompactView(true);
    } else {
      setCompactView(false);
    }
  }

  useEffect(() => {
    window.addEventListener('resize', compactSetter);
    compactSetter();
    return () => window.removeEventListener('resize', compactSetter);
  }, []);

  return (
    <>
      <tbody>
        <tr>
          <th>Functionality</th>
          <th>Other</th>
          <th>{compactView ? 'AIM' : ' Avgator infrastructur Manager'}</th>
        </tr>
        {out}
      </tbody>
    </>
  );
};

const apiData = [
  {
    title: 'GET /sources/room/<Room Reference Name>',
    textArg: 'Room Reference Name: string value of room reference name',
    text500: 'Server responds with 500 on database error or dataset is null. ',
    text404: 'Server responds with HTTP 404 Not found error on data not found',
    text403: 'Server responds with 403 Forbidden error on authorization failed',
    text200:
      'Returns an object, consists of local and global sources which are assigned to the selected room',
    apiLink: 'http://serverAddress/sources/room/Generic_room_one',
    types: {
      local: {
        source: {
          referenceName: 'string',
          crosspointEquipmentId: 'integer',
          location: 'string | null',
          locationId: 'string | null',
          ipAddress: 'string | null',
          controlMethod: 'string | null',
          controlPage: 'string | null',
          drawingDeviceId: 'string | null',
          pdu: 'string | null',
          pduOutlet: 'string | null',
          model: 'string | null',
          sourceType: 'string [ ]',
          nestedSources: {
            nestedSource: {
              referenceName: 'string',
              crosspointEquipmentId: 'integer',
              location: 'string | null',
              locationId: 'string | null',
              ipAddress: 'string | null',
              controlMethod: 'string | null',
              controlPage: 'string | null',
              drawingDeviceId: 'string | null',
              pdu: 'string | null',
              pduOutlet: 'integer | null',
              model: 'string | null',
              sourceType: 'string [ ]',
            },
          },
        },
      },
      global: [],
    },
    resp: {
      local: [
        {
          referenceName: 'DirecTV',
          crosspointEquipmentId: 1002,
          location: 'Generic room one',
          locationId: null,
          ipAddress: null,
          controlMethod: null,
          controlPage: null,
          drawingDeviceId: 'DTV01',
          pdu: null,
          pduOutlet: null,
          model: null,
          sourceType: ['Video', 'Audio'],
          nestedSources: [
            {
              referenceName: 'DirecTVOne',
              crosspointEquipmentId: 1002,
              location: 'Generic room one',
              locationId: null,
              ipAddress: null,
              controlMethod: null,
              controlPage: 'DirecTV',
              drawingDeviceId: 'DTV001',
              pdu: 'APC-GR-01',
              pduOutlet: 1,
              model: null,
              sourceType: ['Video', 'Audio'],
            },
          ],
        },
      ],
      global: [],
    },
  },
  {
    title: 'GET /processor/config/<Processor>',
    textArg:
      'Processor: string representation of processor IP address or Processor Reference name ',
    text500: 'Server responds with 500 on database error or dataset is null',
    text404: 'Server responds with HTTP 404 Not found error on data not found ',
    text403: 'Server responds with 403 Forbidden error on authorization failed',
    text200: 'Returns a dataset of selected Processor',
    apiLink: '/processor/config/10.10.10.10',
    types: {
      DSPNodeID: 'string',
      DSPMatrixID: 'string',
      ID: 'integer   ',
      IPID: 'Dynamic Object Array ',
      Rooms: 'Dynamic Object   ',
    },
    resp: {
      dsp_node_id: '\\x03\\x51',
      dsp_matrix_id: '\\x00\\x01\\x04',
      id: 1,
      ipid: [
        {
          IP: '10.10.10.11',
          NewIPID: '21',
          CurrentIPID: 'D4',
        },
      ],
      rooms: [
        {
          dspGainIDR1: 'volume_room',
          dspGainIDR1Z1: '0',
          dspGainIDR1Z2: '',
          dspGainIDR1Z3: '0',
          dspGainIDR1Z4: '',
          dspGainIDR1Z5: '',
          dspGainIDR1Z6: '',
          dspOP: 2,
          buttonStatus: {
            buttonHomeStatus: true,
            buttonLanguageStatus: false,
          },
          popupStatus: {
            AVpopupStatus: true,
            BlindsPopupStatus: true,
            HVACPopupStatus: false,
          },
          globalRoomNumber: 12,
          supportGroup: '1st level support',
          systemOffPopup: 60,
          totalScreens: 4,
          totalSubzones: 6,
          useLightMap: 0,
          volumeLowerLimit: 10,
          volumeLowerLimitSubzones: {
            volumeLowerLimitSubzone1: 0,
            volumeLowerLimitSubzone2: 0,
            volumeLowerLimitSubzone3: 0,
            volumeLowerLimitSubzone4: 0,
            volumeLowerLimitSubzone5: 0,
            volumeLowerLimitSubzone6: 0,
          },

          volumeTurnOn: 20,
          volumeTurnOnSubzones: {
            volumeTurnOnSubzone1: 0,
            volumeTurnOnSubzone2: 0,
            volumeTurnOnSubzone3: 0,
            volumeTurnOnSubzone4: 0,
            volumeTurnOnSubzone5: 0,
            volumeTurnOnSubzone6: 0,
          },

          volumeUpperLimit: 90,
          volumeUpperLimitSubzones: {
            volumeUpperLimitSubzone1: 0,
            volumeUpperLimitSubzone2: 0,
            volumeUpperLimitSubzone3: 0,
            volumeUpperLimitSubzone4: 0,
            volumeUpperLimitSubzone5: 0,
            volumeUpperLimitSubzone6: 0,
          },
          roomCrosspointControlID: 5401,
          roomCrosspointEquipmentID: 2332,
          roomName: 'Room Name',
        },
      ],
    },
  },
  {
    title: 'GET /sources/',
    textArg: 'None ',
    text500: 'Server responds with 500 on database error or dataset is null',
    text404: 'Server responds with HTTP 404 Not found error on data not found ',
    text403: 'Server responds with 403 Forbidden error on authorization failed',
    text200: 'Returns an array of presented sources',
    apiLink: 'http://serverAddress/sources/',
    types: [
      {
        referenceName: 'string',
        crosspointEquipmentId: 'integer',
        location: 'string | null',
        locationId: 'string | null',
        ipAddress: 'string | null',
        controlMethod: 'string | null',
        controlPage: 'string | null',
        drawingDeviceId: 'string | null',
        pdu: 'string | null',
        pduOutlet: 'string | null',
        model: 'string | null',
        sourceType: 'string [ ]',
        nestedSources: {
          nestedSource: {
            referenceName: 'string',
            crosspointEquipmentId: 'integer',
            location: 'string | null',
            locationId: 'string | null',
            ipAddress: 'string | null',
            controlMethod: 'string | null',
            controlPage: 'string | null',
            drawingDeviceId: 'string | null',
            pdu: 'string | null',
            pduOutlet: 'integer | null',
            model: 'string | null',
            sourceType: 'string [ ]',
          },
        },
      },
    ],
    resp: [
      {
        referenceName: 'DirecTV',
        crosspointEquipmentId: 1002,
        location: 'Generic room one',
        locationId: null,
        ipAddress: null,
        controlMethod: null,
        controlPage: null,
        drawingDeviceId: 'DTV01',
        pdu: null,
        pduOutlet: null,
        model: null,
        sourceType: ['Video', 'Audio'],
        nestedSources: [
          {
            referenceName: 'DirecTVOne',
            crosspointEquipmentId: 1002,
            location: 'Generic room one',
            locationId: null,
            ipAddress: null,
            controlMethod: null,
            controlPage: 'DirecTV',
            drawingDeviceId: 'DTV001',
            pdu: 'APC-GR-01',
            pduOutlet: 1,
            model: null,
            sourceType: ['Video', 'Audio'],
          },
        ],
      },
    ],
  },
  {
    title: 'GET /favorites/',
    textArg: 'None ',
    text500: 'Server responds with 500 on database error or dataset is null',
    text404: 'Server responds with HTTP 404 Not found error on data not found ',
    text403: 'Server responds with 403 Forbidden error on authorization failed',
    text200: 'Returns an object of existing favorites (Dynamic Object Array)',
    apiLink: 'http://serverAddress/favorites/',
    types: null,
    resp: {
      DirecTV: {
        analog_join: 61,
        categories: [
          {
            eng: 'Sport',
            es: 'Deportes',
            channels: [
              {
                eng: 'Sports Main Event',
                es: 'Deportes Main Event',
                ch: '401',
              },
            ],
          },
        ],
      },
    },
  },
];

const ProductsAim = () => {
  useEffect(() => {
    document.title = 'AVGATOR: AIM';
  }, []);

  const [activeAccordeon, setActiveAccordeon] = useState('');

  function accordeonCheckIsActive(name: string) {
    if (activeAccordeon === name) {
      return true;
    } else {
      return false;
    }
  }

  function accordeonHandler(name: string) {
    if (accordeonCheckIsActive(name)) {
      setActiveAccordeon('');
    } else {
      setActiveAccordeon(name);
    }
  }

  return (
    <>
      <TopArea $sliderData={sliderData} />
      <div className='wrap textWrap'>
        <h3 id='products-aim'>Avgator Infrastructure Manager (AIM)</h3>
        <p>
          <a
            style={{ color: '#AC2B42' }}
            target='_blank'
            href='https://aim.avgator.com'
            rel='noreferrer'>
            Help and Download
          </a>
        </p>
        <p>
          Application for configuration, management and control Crestron DM NVX®
          Encoder/Decoders. Provide programming API for the GUI and Control
          systems, Rooms and Source Configuration, Power management, Matrix
          switcher etc.
        </p>
        <s.Table>
          <FullTable />
        </s.Table>

        <s.CarouselBox>
          <ImageGallery
            lazyLoad={false}
            showFullscreenButton
            items={images()}
          />
        </s.CarouselBox>
        <h3>API Samples</h3>

        {apiData.map((item: any) => (
          <s.AccordeonItem
            $isActive={accordeonCheckIsActive(item.title)}
            key={item.title}>
            <s.ClickArea
              onClick={() => {
                accordeonHandler(item.title);
              }}>
              <s.AccordeonHeading>{item.title}</s.AccordeonHeading>
              <Tag tagName='Arg'>{item.textArg}</Tag>
            </s.ClickArea>
            <s.AccordeonContent>
              <Tag
                color='red'
                tagName='500'>
                {item.text500}
              </Tag>
              <Tag
                color='yellow'
                tagName='404'>
                {item.text404}
              </Tag>
              <Tag
                color='yellow'
                tagName='403'>
                {item.text403}
              </Tag>
              <Tag
                color='green'
                tagName='200'>
                {item.text200}
              </Tag>
              {item.types && (
                <ReactJson
                  name={false}
                  collapsed={2}
                  iconStyle='circle'
                  style={{
                    padding: '20px',
                    borderRadius: '5px',
                    fontSize: '12px',
                    lineHeight: '16px',
                    marginBottom: '2em',
                  }}
                  quotesOnKeys={false}
                  displayDataTypes={false}
                  theme='chalk'
                  src={item.types}
                />
              )}
              <Tag tagName='GET'>{item.apiLink}</Tag>
              <ReactJson
                name={false}
                iconStyle='circle'
                style={{
                  padding: '20px',
                  borderRadius: '5px',
                  fontSize: '12px',
                  lineHeight: '16px',
                  marginBottom: '2em',
                }}
                quotesOnKeys={false}
                displayDataTypes={true}
                theme='chalk'
                src={item.resp}
              />
            </s.AccordeonContent>
          </s.AccordeonItem>
        ))}

        {/* <Pricing /> */}
      </div>
      <Footer />
    </>
  );
};

export default ProductsAim;
